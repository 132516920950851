import axios from "axios";

export default {
    namespaced: true,
    state: {
        user: null
    },
    mutations: {
        setUser(state, val) {
            state.user = val || null;
            if (state.user) {
                this.commit("auth/calcCreditsTotal");
            }
        },
        setResponseToken(state, response) {
            axios.defaults.headers.common["X-CSRF-Token"] = response.headers["x-csrf-token"];
        },
        calcCreditsTotal(state, val) {
            if (val) {
                state.user.credit_amount = val.credit_amount;
                state.user.credit_amount_taxable = val.credit_amount_taxable;
            }
            const totalCredits = +state.user.credit_amount + +state.user.credit_amount_taxable;
            if (totalCredits !== state.user?.total_credits) {
                state.user.total_credits = totalCredits;
                this.commit("cart/saveCart");
            }
        },
    }
};

