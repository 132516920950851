<template>
    <div class="circleInfoBox @relative @flex @justify-center @text-center @text-[5px]" :class="{small: mobileText, small: smallSize}">
        <img class="!@max-w-none" :src="$cld.url(gif)" alt="circle info box">
        <template v-if="mobileText">
            <div class="l5 @text-center @text-sfc-white @absolute desktop:@hidden" v-text="mobileText"/>
            <div ref="circleText" class="l2 @text-center @text-sfc-white @absolute @hidden desktop:@block !@text-[16px]" v-html="circleText"/>
        </template>
        <div v-else ref="circleText" class="l1 @uppercase @text-sfc-white" v-html="circleText"/>
    </div>
</template>

<script>
    export default {
        name: "CircleStudios",
        props: {
            circleText: {
                type: String,
                default: "Custom Song"
            },
            gif: {
                type: [Object, String],
                required: true
            },
            mobileText: {
                type: String,
                default: "Your Song"
            },
            smallSize: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            cms() {
                return this.$store.state.cms.data?.songBuilder?.steps;
            }
        },
        watch: {
            circleText() {
                this.fontSizeEditor();
            }
        },
        mounted() {
            this.fontSizeEditor();
        },
        methods: {
            fontSizeEditor() {
                const textEl = this.$refs.circleText;
                let height = textEl?.offsetHeight;
                const maxHeight = this.mobileText || this.smallSize ? 90 : 125;
                while (height > maxHeight) {
                    height = textEl.offsetHeight;
                    const computedStyle = window.getComputedStyle(textEl);
                    const fontSize = computedStyle.getPropertyValue("font-size");
                    const compSize = parseInt(fontSize.replace(/\D/g, "")) - 1;
                    textEl.style.fontSize = compSize+"px";
                }
            }
        }
    };
</script>
