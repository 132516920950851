<template>
    <div class="@px-5 tablet:@px-10 @py-14 @text-left">
        <h1 class="@mb-2.5 @text-sfc-black h3" v-text="title"/>
        <div v-html="content"/>
    </div>
</template>

<script>
    export default {
        name: "CustomSongInfo",
        props: {
            title: {
                type: String,
                default: ""
            },
            content: {
                type: String,
                default: ""
            }
        }
    };
</script>
