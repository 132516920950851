<template>
    <div class="@w-full @mt-0 @m-auto storyBuilderPage storyReviewPage page mobileContainerForDesktop">
        <StoryHeader/>
        <div class="@pt-4 @px-8">
            <SavedStoryData/>
            <div class="@mt-auto @w-full @mt-8">
                <Pronunciation v-if="!pronunciationComplete" @complete="pronunciationComplete = true"/>
                <FormAjax v-else :submit-action="checkout">
                    <MkCheckbox required class="@text-sfc-dark-grey @mb-4" :text="cms.disclaimer"/>
                    <FormError/>
                    <div class="@mb-10 @pt-6 @flex @items-center @w-full">
                        <div class="mkButton beige invertBorder noHoverMob noMinWidth @mr-2" @click="pronunciationComplete = false">
                            Back
                        </div>
                        <FormButton wrap-class="@contents" class="mkButton noMinWidth">
                            COMPLETE YOUR SONG!
                        </FormButton>
                    </div>
                </FormAjax>
            </div>
        </div>
    </div>
</template>

<script>
    import StoryHeader from "@songfinch/studios/brachs/components/story_builder/StoryHeader";
    import Pronunciation from "@songfinch/studios/brachs/components/review_page/Pronunciation";
    import SavedStoryData from "@songfinch/studios/brachs/components/review_page/SavedStoryData";
    import {FormAjax, FormButton, FormError} from "@songfinch/shared/plugins/form_ajax/index";
    import analyticsEvent from "@songfinch/shared/helpers/analyticsEvent";

    export default {
        name: "ReviewPage",
        components: {SavedStoryData, StoryHeader, Pronunciation, FormAjax, FormButton, FormError},
        data() {
            return {
                pronunciationComplete: false,
            };
        },
        computed: {
            songData() {
                return this.$store.state.songBuilder.songData;
            },
            cms() {
                return this.$store.state.cms.data?.reviewPage;
            },
            cart() {
                return this.$store.state.cart.cart;
            }
        },
        methods: {
            async checkout() {
                if (!this.cart.coupon) return this.$router.push({name: "Redeem"});
                try {
                    analyticsEvent("_sf_songbuildersumit_review");
                    const completed_order = await this.$store.dispatch("cart/checkout");
                    return this.$router.push({name: "Story", params: {slug: completed_order.slug}});
                } catch (e) {
                    if (e?.response?.data?.reset_coupon) {
                        this.$store.commit("cart/setCoupon", null);
                    }
                    throw e; //FormAjax will handle error
                }
            }
        }
    };
</script>
