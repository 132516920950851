<template>
    <div>
        <div class="@text-left">
            <h2 v-text="step.title"/>
            <div class="p3 @mb-4" v-html="content"/>
        </div>
        <div class="@w-full @flex @flex-wrap @gap-2">
            <button
                v-for="(item, index) in stepOptions"
                :key="index"
                class="mkButton short beige noMinWidth noHoverMob"
                :class="{active: songData[step.question] === item.title}"
                @click.prevent="songData[step.question] = item.title"
                v-text="item.title"
            />
        </div>
        <RequiredFormHelper v-if="!songData[step.question]" message="Please select an option"/>
    </div>
</template>

<script>
    export default {
        name: "StudiosButton",
        props: {
            step: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                stepOptions: this.step.holidays_list,
                content: null
            };
        },
        computed: {
            songData() {
                return this.$store.state.songBuilder.songData;
            }
        },
        watch: {
            content() {
                //Triggering so slider can resize if content change
                window.dispatchEvent(new Event("resize"));
            }
        },
        created() {
            this.songData[this.step.question] ??= null;
        }
    };
</script>
