<template>
    <div>
        <div class="@flex @items-center @justify-center ">
            <img src="https://content.songfinch.com/static/coca-cola/sf_logo.svg" alt="logo">
            <span class="@inline-block @mx-2.5 @bg-sfc-black" style="width: 2px; height: 42px"/>
            <img src="https://content.songfinch.com/static/brachs/brachs-logo.png" style="max-width: 120px" alt="logo">
        </div>
    </div>
</template>

<script>
    export default {
        name: "LandingLogos"
    };
</script>
