<template>
    <div class="songBuilderSlide @max-w-375 @mx-auto @p-5 @bg-sfc-beige @mt-4 @mb-6">
        <component :is="questions[step.question]" :step="step"/>
    </div>
</template>

<script>
    import {shallowRef} from "vue";
    import InputText from "./fields/InputText";
    import InputEmail from "../song_builder/fields/InputEmail";
    import StudiosButton from "./fields/StudiosButton.vue";
    import ButtonMultiple from "../song_builder/fields/ButtonMultiple";
    import AddressMailing from "../song_builder/fields/AddressMailing";
    import CheckboxInput from "../song_builder/fields/CheckboxInput";
    import HolidayButton from "../song_builder/fields/HolidayButton";

    export default {
        name: "SongBuilderSlide",
        props: {
            step: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                questions: {
                    for_someone_else: shallowRef(StudiosButton),
                    recipient: shallowRef(InputText),
                    gifter_email: shallowRef(InputEmail),
                    genre: shallowRef(StudiosButton),
                    moods: shallowRef(ButtonMultiple),
                    address: shallowRef(AddressMailing),
                    privacy: shallowRef(CheckboxInput),
                    holiday: shallowRef(HolidayButton),
                }
            };
        }
    };
</script>

<style scoped>
    :deep(h2) {
        line-height: normal;
    }
</style>
