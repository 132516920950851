<template>
    <div :class="{show: $route.meta.fixHeader}" class="stikyHeader">
        <div class="@bg-sfc-white @text-center @w-full">
            <div class="@py-2 @max-w-400 @mx-auto @flex @items-center @px-4" :class="$route.meta.hideBackButton ? '@justify-center' : '@justify-between'">
                <a v-if="!$route.meta.hideBackButton" href="#" @click.prevent="$store.commit('songBuilder/slideBack')">
                    <img :src="backIc" alt="back">
                </a>
                <div class="@flex @items-center @justify-center">
                    <img class="@block tablet:@hidden" src="https://content.songfinch.com/static/coca-cola/sf_sm_logo.svg" alt="Songfinch">
                    <img class="@hidden tablet:@block" src="https://content.songfinch.com/static/coca-cola/sf_logo.svg" alt="Songfinch">
                    <span class="@inline-block @mx-2.5 @bg-sfc-black" style="width: 2px; height: 42px"/>
                    <img src="https://content.songfinch.com/static/brachs/brachs-logo.png" style="max-width: 120px" alt="logo">
                </div>
                <div/>
            </div>
        </div>
    </div>
</template>

<script>
    import backIc from "@songfinch/studios/brachs/assets/images/back_ic.svg";
    export default {
        name: "StikyHeader",
        props: {
            showBackButton: {
                type: Boolean,
                default: true
            }
        },
        data() {
            return {
                backIc,
            };
        }
    };
</script>
