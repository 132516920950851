<template>
    <FormAjax :submit-action="savePronunciations">
        <h3 class="@mb-0" v-html="cms.pronunciation.title"/>
        <div class="p1" v-html="cms.pronunciation.subtitle"/>
        <div v-for="(n, i) in pronunciations" :key="i" class="@my-6">
            <label class="form-group @mb-4 l8">
                PERSON, PLACE, OR THING
                <input
                    v-model="n.subject"
                    type="text"
                    class="form-control"
                    :required="hasData"
                    maxlength="50"
                    placeholder="Example: Aretha"
                >
            </label>
            <div class="@mb-5">
                <div class="@flex @items-center l8">
                    PRONOUNCE IT LIKE THIS
                </div>
                <label class="form-group">
                    <input
                        v-model="n.pronounce"
                        type="text"
                        class="form-control"
                        maxlength="50"
                        :required="hasData"
                        placeholder="Example: uh-REE-tha"
                    >
                </label>
            </div>
            <div class="@flex">
                <span
                    v-if="hasData"
                    class="l5 @cursor-pointer mkButton beige noMinWidth short @mr-2"
                    @click="removeItem(i)"
                >
                    Delete
                </span>
                <span
                    v-if="i === pronunciations.length - 1"
                    class="l5 @cursor-pointer mkButton beige noMinWidth short"
                    :class="{disabled: !canAdd}"
                    @click="addItem"
                >add another</span>
            </div>
        </div>
        <!--  Buttons  -->
        <div class="@mb-10 @pt-4 @flex @items-center @w-full">
            <router-link class="mkButton beige invertBorder noHoverMob noMinWidth @mr-2" :to="{name: 'StoryBuilder'}">
                Back
            </router-link>
            <FormButton wrap-class="d-inline-block" class="mkButton noMinWidth">
                <template v-if="hasData">
                    EVERYTHING IS GOOD
                </template>
                <template v-else>
                    skip pronunication
                </template>
            </FormButton>
        </div>
    </FormAjax>
</template>

<script>
    import {nth, every} from "lodash-es";
    import {FormAjax, FormButton} from "@songfinch/shared/plugins/form_ajax/index";
    import analyticsEvent from "@songfinch/shared/helpers/analyticsEvent";

    export default {
        name: "Pronunciation",
        components: {FormAjax, FormButton},
        emits: ["complete"],
        computed: {
            cms() {
                return this.$store.state.cms.data?.reviewPage;
            },
            pronunciations: {
                get() {
                    return this.$store.state.songBuilder.songData.pronunciations;
                },
                set(val) {
                    this.$store.state.songBuilder.songData.pronunciations = val;
                }
            },
            canAdd() {
                return every(nth(this.pronunciations, -1), p => p.trim());
            },
            hasData() {
                return !!(this.pronunciations.length > 1 || this.pronunciations[0].subject || this.pronunciations[0].pronounce);
            }
        },
        beforeCreate() {
            this.$store.state.songBuilder.songData.pronunciations ||= [{subject: "", pronounce: ""}];
        },
        methods: {
            addItem() {
                if (!this.canAdd) return;
                this.pronunciations.push({subject: "", pronounce: ""});
            },
            removeItem(index) {
                if (this.pronunciations.length === 1) {
                    this.pronunciations = [{subject: "", pronounce: ""}];
                } else {
                    this.pronunciations.splice(index, 1);
                }
            },
            removeEmpty() {
                this.pronunciations = this.pronunciations.filter(item => item.subject && item.pronounce);
                if (!this.pronunciations.length) this.addItem();
            },
            savePronunciations() {
                this.removeEmpty();
                if (this.hasData) {
                    analyticsEvent("_sf_songbuildersubmit", "pronunciations");
                }
                this.$store.commit("songBuilder/saveSongData");
                this.$emit("complete");
            }
        }
    };
</script>
