<template>
    <div>
        <div>
            <h2 v-text="step.title"/>
            <div class="p3 @mb-4" v-html="step.content"/>
        </div>
        <div class="@w-full">
            <div class="form-group l8">
                {{step.input_label}}
                <input v-model="songData[step.question]" type="text" required class="form-control">
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "InputText",
        props: {
            step: {
                type: Object,
                required: true
            }
        },
        computed: {
            songData() {
                return this.$store.state.songBuilder.songData;
            }
        },
        created() {
            this.songData[this.step.question] ??= "";
            this.generateAnswerData(this.songData[this.step.question]);
            this.$watch(() => this.songData[this.step.question], this.generateAnswerData);
        },
        methods: {
            generateAnswerData(val, oldVal) {
                if (val) {
                    this.$store.commit("songBuilder/setTextCircle", this.step.circle_placeholder_text.replace("[value]", val));
                } else if (oldVal) {
                    this.$store.commit("songBuilder/setTextCircle", null);
                }
            }
        }
    };
</script>
