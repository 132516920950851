<template>
    <div class="@mt-4 tablet:@mt-12 @text-center tablet:@px-0 @px-0 @pb-10 storySongCompleted">
        <div class="@px-2.5 @max-w-375 @mx-auto">
            <LandingLogos/>
            <h1 class="@mb-0 @mt-12 h3" v-text="cms.title"/>
            <h2 class="p1" v-text="cms.subtitle"/>
            <CircleStudios class="@my-4" small-size :circle-text="circleText" :gif="gif"/>
            <div class="@flex @justify-center @mt-8 @mb-5">
                <PlayButton v-if="storySong" :data="storySong"/>
            </div>
            <div class="@mt-2.5 @mb-26">
                <h3 class="@m-0">
                    {{story.song.title}}
                </h3>
                <div class="p1">
                    by {{story.song.artist.fullName}}
                </div>
            </div>
            <div class="lyricBox @pt-14 @pb-6">
                <h3 class="@mb-4">
                    Lyrics
                </h3>
                <div class="@px-5" v-html="story.song.lyrics"/>
            </div>
            <div v-if="story.download_link" class="@mt-5">
                <a
                    :href="story.download_link"
                    class="mkButton"
                    download
                >download song</a>
            </div>
        </div>
    </div>
</template>

<script>
    import CircleStudios from "@songfinch/studios/brachs/components/song_builder/CircleStudios";
    import LandingLogos from "@songfinch/studios/brachs/components/shared/LandingLogos";

    export default {
        name: "StorySongComplete",
        components: {CircleStudios, LandingLogos},
        props: {
            story: {
                type: Object,
                required: true
            }
        },
        computed: {
            gif() {
                return this.$store.state.cms.data?.songBuilder?.circle;
            },
            cms() {
                return this.$store.state.cms.data?.songComplete.story_song_complite;
            },
            circleText() {
                return `Song for ${this.story.recipient}`;
            },
            storySong() {
                const song = this.story.song;
                return {
                    image: song.artist.photo,
                    name: song.artist.fullName,
                    audio: song.audioUrl,
                    genres: [song.genre],
                    occasions: [],
                    song_title: song.title,
                    story_slug: this.story.slug,
                    song_artist_id: song.artist.id,
                    media_type: "story",
                    player_type: "audio",
                };
            }
        }
    };
</script>
