import axios from "axios";
import {pick} from "lodash-es";
import analyticsEvent from "@songfinch/shared/helpers/analyticsEvent";

export default {
    namespaced: true,
    state: {
        cartStorageName: `sf_cart_${window.appSettings.affiliate}`,
        cart: null, //will set in resetCart
    },
    mutations: {
        setCoupon(state, val) {
            state.cart.coupon = {
                code: val
            };
            this.commit("cart/saveCart");
        },
        saveCart(state) {
            localStorage.setItem(state.cartStorageName, JSON.stringify(state.cart));
        },
        resetCart(state, save) {
            state.cart = {
                branded: true,
                coupon: {
                    code: null
                },
                reportedUserSignIn: false
            };
            save && this.commit("cart/saveCart");
        },
        initCart(state) {
            this.commit("cart/resetCart");
            //Load saved
            const savedCart = localStorage.getItem(state.cartStorageName);
            if (savedCart) {
                state.cart = {...state.cart, ...JSON.parse(savedCart)};
            }
        }
    },
    actions: {
        async loadCoupon({commit}, coupon) {
            //Temp till we have endpoint
            let res, cpn = null, error;
            try {
                res = await axios.get(`branded_studio/api/v1/codes/validate/${coupon}`, {baseURL: "/"});
                cpn = res.data.coupon;
            } catch (e) {
                error = e;
            }
            //load coupon or reset
            commit("setCoupon", cpn?.code);
            if (error) throw error;
        },
        async checkout({state}) {
            let  res = await axios.get(`/store/single_product/personalized-song`);
            const songProduct = res.data;

            const data = {
                sf_song_data: this.getters["songBuilder/getCheckoutData"],
                sf_cart: {
                    purchase_channel: "branded-studio",
                    affiliate: window.appSettings.affiliate,
                    addresses: {shipping: null},
                    cart_total: 0,
                    //TODO: Future use case I'm thinking we should build this
                    // In session ahead of time so this is not overrideable by users
                    rush_fee: {add_days: "7", cost: "0"},
                    coupon_code: state.cart.coupon?.code,
                    products: [
                        pick(songProduct, ["id", "price", "name", "product_type"])
                    ],
                    user_id: null
                },
                submission_id: null
            };

            res = await axios.post("checkout/submit", data);
            const {guest_user_created, submission_id} = res.data;
            //Submitting should create the user
            !this.reportedUserSignIn && analyticsEvent("_sf_user_login", {
                registration: guest_user_created
            });
            //Store a flag to not resubmit if there is any issue
            this.reportedUserSignIn = true;
            res = await axios.post("checkout/confirm_order", {submission_id});
            const completed_order = res.data;
            analyticsEvent("_sf_purchase", {
                order: completed_order,
                song_product: songProduct,
            });

            this.commit("cart/setCoupon", null);
            this.commit("cart/resetCart", true);
            this.commit("songBuilder/resetSongData", true);
            return completed_order;
        }
    }
};
