<template>
    <div>
        <div>
            <h2 v-text="step.title"/>
            <MkCheckbox v-model="songData[step.question]" class="centered">
                <span v-html="step.content"/>
            </MkCheckbox>
        </div>
        <RequiredFormHelper v-if="!songData[step.question]" message="Please select an option"/>
    </div>
</template>

<script>
    export default {
        name: "InputText",
        props: {
            step: {
                type: Object,
                required: true
            }
        },
        computed: {
            songData() {
                return this.$store.state.songBuilder.songData;
            }
        },
        created() {
            this.songData[this.step.question] ??= "";
        }
    };
</script>
