<template>
    <button ref="dynamicLink" class="mkButton noHoverMob noMinWidth @relative nextSlideButton">
        <span
            v-for="(step, i) in cms"
            :key="i"
            ref="spans"
            class="@absolute"
            :style="{opacity: activeIndex === i ? 1 : 0}"
        >
            NEXT: {{cms[+i + 1]?.step_name || `Let's get the story`}}
        </span>
    </button>
</template>

<script>
    export default {
        name: "NextSlideButton",
        props: {
            activeIndex: {
                type: Number,
                default: 0
            }
        },
        computed: {
            cms() {
                return this.$store.state.cms.data?.songBuilder?.steps;
            }
        },
        watch: {
            activeIndex(val) {
                this.buttonAnimation(val);
            }
        },
        methods: {
            buttonAnimation(index) {
                if (!this.$refs.dynamicLink) return;
                this.$refs.dynamicLink.style.width = (this.$refs.spans?.[index].offsetWidth + 50) + "px";
            },
        }
    };
</script>
