<template>
    <div class="@my-8 @max-w-400 @mx-auto">
        <div v-if="props.showSocial" class="@flex @justify-center @gap-x-4">
            <a
                v-for="(slink, i) in data.social_links"
                :key="i"
                :aria-label="`Link to Songfinch's ${slink.name}`"
                target="_blank"
                :href="slink.link"
            >
                <img :src="$cld.url(slink.icon)" width="28" height="28" :alt="`${slink.name}`">
            </a>
        </div>
        <div v-if="props.showText" class="@text-[12px] @leading-none @text-center @mt-3.5 mobile:@px-4" style="font-size: 12px" v-html="data.subtitle"/>
    </div>
</template>

<script setup lang="ts">
    import {computed} from "vue";
    import {useStore} from "vuex";

    const store = useStore();

    const props = defineProps({
        showSocial: {type: Boolean, default: true},
        showText: {type: Boolean, default: false},
    });

    const data = computed(()=> store.state.cms.data?.footer);

</script>
