<template>
    <div>
        <div>
            <h2 v-text="step.title"/>
            <div class="p3 @mb-4" v-html="step.content"/>
        </div>
        <div class="@w-full">
            <div class="form-group l8 @pb-3.5">
                Name
                <input v-model="songData['address_name']" type="text" required class="form-control">
            </div>
            <div class="form-group l8 @pb-3.5">
                Street Address
                <input v-model="songData['address_line1']" type="text" required class="form-control">
            </div>
            <div class="form-group l8">
                City, State, Zip
                <input v-model="songData['address_line2']" type="text" required class="form-control">
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "InputText",
        props: {
            step: {
                type: Object,
                required: true
            }
        },
        computed: {
            songData() {
                return this.$store.state.songBuilder.songData;
            }
        }
    };
</script>
