import {compact, reduce} from "lodash-es";
import Cookies from "js-cookie";
import uuid from "@songfinch/shared/helpers/uuid";
import sha256 from "@songfinch/shared/helpers/sha256";

import app from "@songfinch/studios/brachs/app.js";
import router from "@songfinch/studios/brachs/router";
import store from "@songfinch/studios/brachs/store";

const dbVals = window.appSettings.events_value;
const affiliate = window.appSettings.affiliate;
const SONG_BUILDER_QUIZ_NAME = affiliate;
const SONG_BUILDER_QUIZ_NUMBER = "3";

const NON_ADDON_PRODUCTS = ["song", "gift-card"];
const empty_songbuilder_values = [null, "", undefined];

let trackData;

function generateTrackData() {
    const result = {};
    const steps = {
        for_someone_else: "BsSongRecipientChoice",
        recipient: "BsSongRecipientName",
        gifter_email: "BsOccasionGifter",
        genre: "BsSongGenre",
        moods: "BsSongMood",
    };
    let index = 0;
    store.state.cms.data.songBuilder.steps.forEach( item => {
        index++;
        result[item.question] = {step_number: index, step_name: steps[item.question]};
    });
    result["questions"] = {step_number: ++index, step_name: "BsStoryQuestions"};
    result["pronunciations"] = {step_number: ++index, step_name: "BsStoryPronunciation"};
    result["review"] = {step_number: ++index, step_name: "BsReview"};
    return result;
}

function mapBackendProductToEventProductModel(p) {
    // Note we are remapping Product.title => TrackedProduct.name for using the "human readable name when forwarding
    // Product.name => TrackedProduct.code
    return {
        id: p.sku,
        product_id: p.sku,
        content_id: p.sku,
        content_type: "product",
        name: p.title,
        code: p.name,
        quantity: p.quantity,
        brand: "songfinch",
        category: p.product_type,
        price: p.price / 100,
        currency: "USD",
        product_type: p.product_type,
        add_on: !NON_ADDON_PRODUCTS.includes(p.product_type),
        image_url: app.config.globalProperties.$cld.url(p.photo) || "",
        variant_name: p.extra_info?.variant_name
    };
}

function getUserEmailFromAuthOrSongStore() {
    const user = store.state.auth?.user;
    return user?.email || store.state.songBuilder.songData?.gifter_email;
}

function getCouponCodeFromStateStoreCartData() {
    return store.state.cart.cart?.coupon?.code;
}


function getUserTraitsForSegmentRequest() {
    // Only have access to email from song builder
    const email = getUserEmailFromAuthOrSongStore();
    const em = sha256(email);
    //Only return session data if we have information about the user's email
    return email ? {
        email,
        em,
    } : {};
}

function getSessionDataForSegmentRequest() {
    return {
        gclid: Cookies.get("gclid"),
        fbc: Cookies.get("_fbc"),
        fbp: Cookies.get("_fbp"),
        user_id: getUserIdentifierFromCookie(),
    };
}

function getSegmentTrackingContextData(eventId = null, eventName = null) {
    // If we don't have an eventId passed in, generate one with UUID
    const event_id_nonce = eventId ? eventId : uuid().replace("-", "");
    //Construct a tracking event_id based on if we passed on an eventName
    const tracking_event_id = eventName ? `${eventName}-${event_id_nonce}` : event_id_nonce;
    return {
        traits: getUserTraitsForSegmentRequest(),
        session: getSessionDataForSegmentRequest(),
        event_id: tracking_event_id,
        timestamp: dateTimeInSeconds()
    };
}

function getUserIdentifierFromCookie() {
    return Cookies.get("ajs_user_id") ||
        Cookies.get("ajs_anonymous_id") ||
        Cookies.get("_cioanonid");
}

function mapAndEnrichSongbuilderDataForSegment(step_name = null, step_number = null) {
    const songData = store.state.songBuilder.songData;
    //Set the step_name/number
    const segmentData = step_name ? {
        step_name,
        step_number,
    } : {};
    const for_someone_else = songData.for_someone_else?.id || "No";
    const gifter = for_someone_else === "No" ? songData.recipient : songData.gifter_email;

    segmentData.song_customer_email = songData.gifter_email;
    segmentData.song_customer_name = gifter;
    segmentData.song_recipient = songData.recipient;
    segmentData.song_for_someone_else = songData.for_someone_else === "Yes";
    segmentData.song_for_occasion = songData.occasion;
    segmentData.song_moods = songData.moods.map(m => dbVals.moods[m.id]).join(", ");
    segmentData.song_genre = songData.genre?.text;
    segmentData.song_gender = songData.gender?.id || "3"; //Surprise static
    segmentData.song_story_details = window.appSettings.story_subject;
    segmentData.song_pronunciations = songData.pronunciations;
    const questions = [
        {key: "message", question: "What’s the message?", answer: songData.message_question},
        ...(songData?.questions || []).filter(q => q.answer)
    ];
    segmentData.song_must_have_questions = reduce(questions,
        (acc, el) => {
            if (el.answer) {
                acc[el.key] = {
                    title: el.question,
                    answer: el.answer,
                };
            }
            return acc;
        }, {});

    //Strip "empty" objects from reporting (except for false)
    return Object.fromEntries(Object.entries(segmentData)
        .filter(([, v]) => {
            //Test if any values match an "empty" value, if so, we exclude
            return !empty_songbuilder_values.some((val) => {
                return val === v || (typeof b === "object" && Object.getOwnPropertyNames(v).length === 0);
            });
        })
    );
}

function dateTimeInSeconds() {
    return Math.round(Date.now() / 1000);
}

document.addEventListener("_sf_pageview", (e) => {
    const eventId = "PageView-" + e.detail?.request_id;
    window.dataLayer?.push({ecommerce: null});
    window.dataLayer?.push({
        event: "pageview",
        page: {
            name: router.currentRoute.value.name,
            path: location.pathname,
            pageTitle: document.title,
        },
        fbEventID: eventId
    });

    window.analytics?.page(router.currentRoute.value.name, {
        affiliate,
        fbEventID: eventId,
        event_id: eventId,
        requestId: e.detail?.request_id,
        ...getSegmentTrackingContextData(eventId)
    });
});

//Apply Coupon
document.addEventListener("_sf_apply_coupon", () => {
    const coupon_code = getCouponCodeFromStateStoreCartData();
    if (!coupon_code) return;
    window.dataLayer?.push({
        event: "apply_coupon",
        ecommerce: {
            coupon: coupon_code
        }
    });
    const discountTotal = window.appSettings.couponDiscountForRedemptionCents / 100;
    window.analytics?.track("Checkout Coupon Applied", {
        affiliate,
        coupon: coupon_code,
        coupon_total: discountTotal,
    }, getSegmentTrackingContextData(null, "Checkout Coupon Applied"));
});

//user login event
document.addEventListener("_sf_user_login", (e) => {
    //Pull email data from client state store
    const user_email = getUserEmailFromAuthOrSongStore();
    const user_id = getUserIdentifierFromCookie();
    const user = {
        id: getUserIdentifierFromCookie(),
        email: getUserEmailFromAuthOrSongStore(),
        //TODO: Do we want to set any defaults?
        first_name: "",  //"Awesome",
        last_name: "",  //"Customer",
        heard_about_from: window.appSettings.affiliate,
        referral_code: getCouponCodeFromStateStoreCartData()
    };
    window.dataLayer?.push({
        event: "UserInit",
        user_id: user.id,
        user_email: user.email,
        user_first_name: user.first_name,
        user_last_name: user.last_name,
        user_role: "",
    });

    if (e.detail.registration) {
        window.dataLayer?.push({
            event: "signup",
            page: {
                path: location.pathname,
                pageTitle: document.title,
            },
            user
        });

        window.analytics?.track("Account Created", {
            email: user.email,
            firstName: user.first_name,
            lastName: user.last_name,
            referral_source: user.heard_about_from,
            referral_code: store.state.auth.referralCode || ""
        }, getSegmentTrackingContextData(user_id, "Account Created"));
    } else {
        window.analytics?.track("Signed In", {
            email: user_email,
        }, getSegmentTrackingContextData(null, "Signed In"));
    }
});

//Song Builder Forms Submissions
document.addEventListener("_sf_songbuildersubmit", (e) => {
    trackData ??= generateTrackData();
    const {step_name, step_number} = trackData[e.detail];
    const segmentData = mapAndEnrichSongbuilderDataForSegment(step_name, step_number);
    //Identify the user to the anon id using the email after first step is submitted
    // if we haven't already identified
    if (document.cookie.indexOf("ajs_user_id=") === -1 && step_name === "BsOccasionGifter") {
        //We refer to this person as the Gifter but in segment we store this on the song_customer_email field
        window.analytics?.identify({
            email: getUserEmailFromAuthOrSongStore()
        }, getSegmentTrackingContextData(null, null));
    }

    window.dataLayer?.push({
        event: "songbuildersubmit",
        page: router.currentRoute.value.name,
        ...segmentData
    });

    window.analytics?.track("Songbuilder Step Completed", {
        affiliate,
        step_name,
        step_number,
        song_customer_category: "lead",
        quiz_name: SONG_BUILDER_QUIZ_NAME,
        quiz_version: SONG_BUILDER_QUIZ_NUMBER,
        ...segmentData,
    }, getSegmentTrackingContextData(null, "Songbuilder Step Completed"));
});

document.addEventListener("_sf_songbuildersumit_review", () => {
    //Legacy behavior for GTM
    const segmentData = mapAndEnrichSongbuilderDataForSegment();
    window.analytics?.track("Songbuilder Completed", {
        affiliate,
        song_customer_category: "lead",
        quiz_name: SONG_BUILDER_QUIZ_NAME,
        quiz_version: SONG_BUILDER_QUIZ_NUMBER,
        ...segmentData,
    }, getSegmentTrackingContextData(null, "Songbuilder Completed"));
});

document.addEventListener("_sf_purchase", (e) => {
    const {order, song_product} = e.detail;
    if (!order || !song_product) return;
    const page_data = {
        name: router.currentRoute.value.name,
        path: location.pathname,
        pageTitle: document.title,
    };
    //Map products in response object from API to product event product model
    const nonSongReportedProducts = order.non_song_product_data.map(mapBackendProductToEventProductModel);
    const user_email = getUserEmailFromAuthOrSongStore();
    const cart_stage = "Song Purchase";
    const discountTotal = (song_product.price || window.appSettings.couponDiscountForRedemptionCents) / 100;
    const order_fb_event_id = "Purchase-" + order.order_id;
    const coupon_code = order.coupon?.code || getCouponCodeFromStateStoreCartData();
    // Construct products array from song product info (quantity: 1), and any nonSongReportedProducts
    const products = compact(
        [mapBackendProductToEventProductModel({quantity: 1, ...song_product})]
            .concat(...nonSongReportedProducts)
    );

    const gtmData = {
        event: "purchase",
        page: page_data,
        ecommerce: {
            contents: products,
            value: 0,
            coupon: coupon_code,
            coupon_total: discountTotal,
            currency: "USD",
            subtotal: 0,
            credit_applied: 0,
            user: {
                email: user_email,
            },
            affiliate,
            transactionID: order.order_id,
            chargeID: order.charge_id,
            purchase: {
                actionField: {
                    id: "T" + order.order_id, // Transaction ID. Required for purchases and refunds.
                    affiliation: "Songfinch Store",
                    revenue: 0,
                    tax: 0,
                    shipping: 0,
                    coupon: coupon_code,
                    currency: "USD"
                },
                products
            }
        },
        fbEventID: order_fb_event_id
    };
    // Segment
    const segmentOrderData = {
        song_included: true,
        checkout_id: "T" + order.order_id,
        coupon: coupon_code,
        currency: "USD",
        credit_applied: 0,
        discount: discountTotal,
        order_id: order.order_id,
        payment_method: order.payment_type,
        shipping: 0,
        subtotal: 0,
        tax: 0,
        total: 0,
        value: 0,  // ENG-1112 - Adding tracking for segment pixel usage
        fbEventID: order_fb_event_id,
        products
    };
    const segmentSongData = {
        // Order Ecomm Attributes
        checkout_id: "T" + e.detail.order_id,
        coupon: coupon_code,
        currency: "USD",
        credit_applied: 0,
        discount: discountTotal,
        order_id: order.order_id,
        payment_method: order.payment_type,
        shipping: 0,
        subtotal: 0,
        tax: 0,
        total: 0,
        value: 0,  // ENG-1112 - Adding tracking for segment pixel usage
        products,
        // Song Ecomm Attributes
        story_id: order.slug,
        price: song_product.price / 100,
        add_on: false,
        // SongBuilder Attributes
        ...mapAndEnrichSongbuilderDataForSegment(),
    };
    //Enrich the product event model w/ order info to associate w/ the order/story
    const segmentItemData = nonSongReportedProducts.map((p) => {
        return {
            order_id: order.order_id,
            checkout_id: "T" + order.order_id,
            story_id: order.slug,
            coupon: coupon_code,
            value: p.price,  // ENG-1112 - Adding tracking for segment pixel usage
            ...p
        };
    });

    window.dataLayer?.push({ecommerce: null});
    window.dataLayer?.push({
        ...gtmData,
    });
    window.analytics?.track("Order Completed", {
        affiliate,
        cart_stage,
        timestamp: dateTimeInSeconds(),
        ...segmentOrderData,
    }, getSegmentTrackingContextData(order.order_id, "OrderCompleted"));

    window.dataLayer?.push({ecommerce: null});
    window.dataLayer?.push({
        event: "song_purchase",
        page: page_data,
        ecommerce: {
            affiliate,
            ...gtmData.ecommerce,
        },
        fbEventID: `SongPurchased-${order.order_id}`
    });

    window.analytics?.track("Song Purchased", {
        affiliate,
        cart_stage,
        ...segmentSongData,
    }, getSegmentTrackingContextData(order.order_id, "SongPurchased"));

    //Only report purchased products
    segmentItemData.forEach((p) => {
        window.analytics?.track("Item Purchased", {
            affiliate,
            cart_stage,
            timestamp: dateTimeInSeconds(),
            ...p,
        }, getSegmentTrackingContextData(`${order.order_id}-${p.id}`, "ItemPurchased"));
    });
});
