<template>
    <div class="topHead @w-full">
        <div class="@flex desktop-xl:@block tablet:@text-center @text-left @pt-6 @px-5 @mb-5 @items-center @justify-center">
            <CircleStudios class="@mb-0 desktop:@mb-5" :circle-text="circleText" mobile-text="Your Song" :gif="gif"/>
            <div class="@mx-2.5 @mb-0 desktop:@mb-2.5">
                <div class="l3 @mb-1 desktop:@hidden">
                    Song for {{songData.recipient}}
                </div>
                <div class="l8" v-html="subTitle"/>
            </div>
            <router-link class="mkButton beige invertBorder noHoverMob mini" :to="{name: 'SongBuilder'}">
                Edit
            </router-link>
        </div>
    </div>
</template>

<script>
    import indefinite from "indefinite";
    import CircleStudios from "@songfinch/studios/brachs/components/song_builder/CircleStudios";

    export default {
        name: "StoryHeader",
        components: {CircleStudios},
        computed: {
            gif() {
                return this.$store.state.cms.data?.songBuilder?.circle;
            },
            songData() {
                return this.$store.state.songBuilder.songData;
            },
            subTitle() {
                if (!this.songData.genre || !this.songData.moods) return  "";
                const genre = this.songData.genre.text;
                const genreArticle = indefinite(genre, {articleOnly: true});
                const moods = this.songData.moods.map(m => m.text).join(" & ");
                const moodsArticle = indefinite(moods, {articleOnly: true});
                return `${genreArticle} <strong>${genre}</strong> song <br/>with ${moodsArticle} <strong>${moods}</strong> vibe`;
            },
            circleText() {
                return `Song for ${this.songData?.recipient}`;
            }
        }
    };
</script>
