<template>
    <FormAjax ref="slideForm" :submit-action="complete" class="@w-full @mb-auto storyBuilderPage page mobileContainerForDesktop">
        <StoryHeader/>
        <div class="@pt-0 @container @px-8 tablet:@text-center @relative @z-10">
            <h3 class="@mb-0" v-html="cms.story_content.title_message"/>
            <div class="@mb-0 p3 @pb-4" v-html="cms.story_content.content_message"/>
            <TextareaWithLimit
                id="message_question"
                v-model="songData.message_question"
                wrapper-class="@mt-0 @pt-2"
                :maxlength="maxLength"
                :minlength="minLength"
                required
            />
        </div>
        <div class="@w-full @my-8 tablet:@my-0 tablet:@mt-10 @overflow-hidden">
            <hr class="@bg-sfc-dark-grey tablet:@hidden @m-0">
        </div>
        <div class="@w-full tablet:@text-center">
            <h3 class="@mb-0 @px-8" v-html="cms.story_content.title_addon"/>
            <div class="@mb-0 p3 @pb-1 @px-8 tablet:@px-2 tablet:@max-w-375 @mx-auto" v-html="cms.story_content.content_addon"/>
            <div class="spliderTextContainer @text-left" :class="{firstSlide: isFirst, lastSlide: isLast, onFocus: isFocused}">
                <Slider
                    v-bind="sliderOptions"
                    ref="slider"
                    class="!@mb-0"
                    :arrows="true"
                    :custom-arrow="$cld.url('https://content.songfinch.com/static/coca-cola/sliderArrow.png', {fetch_format: null})"
                >
                    <SplideSlide v-for="(question, i) in songData.questions" :key="i" :data-index="i">
                        <div class="@px-2 tablet:@px-0 tablet:@max-w-375">
                            <div class="l8 @pt-2" v-html="question.question"/>
                            <TextareaWithLimit
                                :id="question.key"
                                v-model="question.answer"
                                class="@mb-2"
                                :maxlength="maxLength"
                                :minlength="minLength"
                                data-slide-text="1"
                                @focus="isFocused = true"
                                @blur="isFocused = false"
                            />
                        </div>
                    </SplideSlide>
                </Slider>
            </div>
        </div>
        <div class="@mt-auto @w-full">
            <RequiredFormHelper v-if="answeredQuestions" message="Please complete at least 2 prompts above"/>
            <FormError class="@container @text-center"/>
            <div class="@my-10 @flex @items-center @px-8 @w-full">
                <router-link class="mkButton beige invertBorder noHoverMob noMinWidth @mr-2" :to="{name: 'SongBuilder'}">
                    Back
                </router-link>
                <FormButton wrap-class="@inline-block" class="mkButton noHoverMob noMinWidth @relative" @click="validateForm">
                    review everything
                </FormButton>
            </div>
        </div>
    </FormAjax>
</template>

<script>
    import StoryHeader from "@songfinch/studios/brachs/components/story_builder/StoryHeader";
    import sliderValidity from "@songfinch/studios/brachs/helpers/slider_validity";
    import {FormAjax, FormButton, FormError} from "@songfinch/shared/plugins/form_ajax/index";
    import analyticsEvent from "@songfinch/shared/helpers/analyticsEvent";

    export default {
        name: "StoryBuilder",
        components: {StoryHeader, FormAjax, FormButton, FormError},
        mixins: [sliderValidity],
        data() {
            return {
                isLast: false,
                isFirst: true,
                isFocused: false,
                sliderOptions: {
                    options: {
                        trimSpace: true,
                        rewind: false,
                        pagination: false,
                        padding: {left: 30, right: 185},
                        drag: false,
                        breakpoints: {
                            1199: {
                                drag: true,
                            },
                            767: {
                                trimSpace: false,
                                focus: "center",
                                padding: 24,
                            }
                        }
                    },
                    events: {
                        "splide:move": (splide, newIndex) => {
                            this.isLast = newIndex === this.songData.questions?.length - 1;
                            this.isFirst = newIndex === 0;
                        },
                        "splide:dragging": () => {
                            this.$refs.slider.$el.querySelector(":focus")?.blur();
                        }
                    }
                }
            };
        },
        computed: {
            songData() {
                return this.$store.state.songBuilder.songData;
            },
            cms() {
                return this.$store.state.cms.data?.storyBuilder;
            },
            maxLength() {
                return this.cms?.max_length || 500;
            },
            minLength() {
                return this.cms?.min_length || 200;
            },
            answeredQuestions() {
                return this.songData.questions.filter(q => q.answer).length < 2;
            }
        },
        created() {
            this.songData.questions = this.cms.questions.map(q => {
                const question = {...q};
                question.answer = this.songData.questions?.find(a => a.key === q.key)?.answer || "";
                return question;
            });
        },
        mounted() {
            if (this.$route.hash) {
                const editField = this.$refs.slideForm.$el.querySelector(this.$route.hash);
                if (editField) {
                    setTimeout(() => {
                        if (editField?.dataset.slideText) {
                            this.gotoSlideWithError(this.$refs.slider, editField);
                        } else {
                            editField.focus();
                        }
                    }, 300);

                }
            }
        },
        methods: {
            removeEmpty() {
                this.songData.questions = this.songData.questions.filter(q => q.answer?.trim());
            },
            validateForm(e) {
                const firstInvalid = this.$refs.slideForm.$el.querySelector(":invalid");
                if (firstInvalid?.dataset.slideText) {
                    e.preventDefault();
                    return this.gotoSlideWithError(this.$refs.slider, firstInvalid);
                }
            },
            complete() {
                this.removeEmpty();
                this.$store.commit("songBuilder/saveSongData");
                analyticsEvent("_sf_songbuildersubmit", "questions");
                this.$router.push({name: "ReviewPage"});
            }
        }
    };
</script>
