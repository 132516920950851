<template>
    <div>
        <div class="@text-left">
            <h2 v-text="step.title"/>
            <div class="p3 @mb-4" v-html="content"/>
        </div>
        <div class="@w-full @flex @flex-wrap @gap-2">
            <button
                v-for="(item) in stepOptions"
                :key="item.option.id"
                class="mkButton short beige noMinWidth noHoverMob"
                :class="{active: songData[step.question]?.id === item.option.id}"
                @click.prevent="songData[step.question] = item.option"
                v-text="item.option.text"
            />
        </div>
        <RequiredFormHelper v-if="!songData[step.question]" message="Please select an option"/>
    </div>
</template>

<script>
    import indefinite from "indefinite";

    export default {
        name: "StudiosButton",
        props: {
            step: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                stepOptions: this.step[this.step.question],
                content: null
            };
        },
        computed: {
            songData() {
                return this.$store.state.songBuilder.songData;
            }
        },
        watch: {
            content() {
                //Triggering so slider can resize if content change
                window.dispatchEvent(new Event("resize"));
            }
        },
        created() {
            this.songData[this.step.question] ??= null;
            this.generateAnswerData(this.songData[this.step.question]);
            this.$watch(() => this.songData[this.step.question], this.generateAnswerData);
        },
        methods: {
            generateAnswerData(val, oldVal) {
                const id = val?.id;
                if (!id) {
                    if (oldVal) this.$store.commit("songBuilder/setTextCircle", null);
                    return this.content = this.step.content;
                }
                const optionIndex = this.stepOptions.findIndex(o => o.option.id === id);

                if (optionIndex === -1) {
                    this.songData[this.step.question] = null;
                    return this.content = this.step.content;
                }
                this.content = this.stepOptions[optionIndex].content_below || this.step.content;
                if (this.stepOptions[optionIndex].circle_text) {
                    this.$store.commit("songBuilder/setTextCircle", this.stepOptions[optionIndex].circle_text);
                } else {
                    const circleText = this.step.circle_placeholder_text.replace("[value]", indefinite(val.text, {capitalize: true})).replace("[name]", this.songData.recipient);
                    this.$store.commit("songBuilder/setTextCircle", circleText);
                }
            }
        }
    };
</script>
