<template>
    <div class="redeemPage page">
        <div class="@relative @text-center @flex @justify-center @items-center heroSection mobile:@overflow-hidden">
            <h3
                v-if="timePos === 'in_time'"
                class="@max-w-335 tablet:@max-w-525 @w-full @mt-12 tablet:@mt-20 tablet:@pt-1.5 heroTextBg"
                v-text="cms.landing_page.title"
            />
            <h1
                v-if="timePos === 'early'"
                class="@max-w-335 tablet:@max-w-525 @w-full @mt-26 heroTextBg"
                v-text="cms.landing_early.title"
            />
            <div v-if="timePos === 'late'" class="@max-w-335 tablet:@max-w-525 @w-full @mt-14 tablet:@mt-18 heroTextBg">
                <h2 class="@mb-1.5" v-text="cms.landing_late.title"/>
                <div class="p1" v-text="cms.landing_late.subtitle"/>
            </div>
        </div>
        <div class="@px-8">
            <div class="@max-w-335 desktop:@max-w-615 @mx-auto @text-center">
                <div class="content" v-html="content"/>
                <div class="@my-8">
                    <p v-if="timePos === 'in_time'">
                        <router-link class="mkButton" :to="{name: 'SongBuilder'}">
                            START YOUR SONG
                        </router-link>
                    </p>
                    <a v-if="timePos !== 'late'" href="#" class="l5 !@decoration-1 text-decoration-underline" @click.prevent="learnmore">
                        Learn More
                    </a>
                    <a v-if="timePos === 'late' && cms.landing_late.carol_file" :href="$cld.url(cms.landing_late.carol_file)" class="mkButton" download="download">
                        download carol
                    </a>
                </div>
                <div class="disclaimer" v-html="disclaimer"/>
            </div>

            <MediaFooter :show-text="false"/>
        </div>
    </div>
</template>

<script>
    import CustomSongInfo from "@songfinch/studios/brachs/components/popups/CustomSongInfo";
    import MediaFooter from "@songfinch/studios/brachs/components/shared/MediaFooter";

    export default {
        name: "Redeem",
        components: {MediaFooter},
        data() {
            return {
                timePos: ""
            };
        },
        computed: {
            cms() {
                return this.$store.state.cms.data?.redeem;
            },
            startDate() {
                return this.$store.state.cms.data?.event_settings?.startDate;
            },
            cart() {
                return this.$store.state.cart.cart;
            },
            content() {
                if (this.timePos === "early") return this.cms.landing_early.content;
                if (this.timePos === "late") return this.cms.landing_late.content;
                return this.cms.landing_page.content;
            },
            disclaimer() {
                if (this.timePos === "early") return this.cms.landing_early.disclaimer;
                if (this.timePos === "late") return this.cms.landing_late.disclaimer;
                return this.cms.landing_page.disclaimer;
            }
        },
        created() {
            const curDate = this.$dayjs.tz(new Date(), "America/Chicago");
            const startDate = this.$dayjs.tz(this.startDate, "America/Chicago");
            if ((startDate.unix() > curDate.unix())) this.timePos = "early";
            else if ((startDate.unix() <= curDate.unix()) && this.cart.coupon?.code) this.timePos = "in_time";
            else this.timePos = "late";
        },
        methods: {
            learnmore() {
                this.$store.commit("shared/showModal", {
                    component: CustomSongInfo,
                    props: {
                        title: this.cms.popup.title,
                        content: this.cms.popup.content,
                    },
                    slide: "bottom",
                    swalSettings: {
                        width: 820,
                    }
                });
            },
        },
    };
</script>

<style scoped>
    .redeemPage {
        background-color: var(--sfc-white);

        h1 {
            font-size: 48px;
            line-height: normal;
        }

        h2 {
            font-size: 38px;
            line-height: normal;
        }

        .p1 {
            font-size: 22px;
            line-height: normal;
            color: var(--sfc-white);
            font-weight: 380;
        }

        .heroSection {
            background-image: url("https://content.songfinch.com/static/brachs/brachs-hero.png");
            background-color: var(--sfc-brachs-red);
            background-position: center bottom;
            background-size: cover;
            height: 350px;
            position: relative;
            @screen tablet {
                height: 354px;
            }

            @screen desktop-xl {
                height: 400px;
            }

            @media (min-width: 1920px) {
                height: 500px;
            }

            &:after {
                content: "";
                width: 100%;
                height: 5px;
                left: 0;
                bottom: -2px;
                position: absolute;
                background-color: var(--sfc-white);
                z-index: 99;
            }

            &:before {
                content: "";
                top: 50px;
                width: 100%;
                left: 0;
                position: absolute;
                background: url("https://content.songfinch.com/static/brachs/candy-canes-line.png") no-repeat center bottom;
                background-size: contain;
                z-index: 100;
                height: 525px;
                pointer-events: none;

                @screen mobile {
                    display: none;
                }
            }

            .heroTextBg {
                position: absolute;
                top: 0;
                left: 50%;
                transform: translateX(-50%);
                color: var(--sfc-white);

                &:before {
                    content: "";
                    width: 120%;
                    height: 120%;
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    border-radius: 626px;
                    background: #ED1C24;
                    filter: blur(25px);
                    z-index: -1;
                }
            }
        }

        .content :deep(p) {
            font-size: 18px;
            color: var(--sfc-black);
            margin-bottom: 14px;
        }

        .disclaimer :deep(p) {
            font-size: 12px;
            color: var(--sfc-black);
            line-height: normal;
            margin-bottom: 4px;
        }
    }
</style>
